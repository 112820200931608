import React from 'react';

import AnchorLink from "react-anchor-link-smooth-scroll";

export const Feature = () => {
  return (
    <div className="feature feature-2">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/adminfeatpro.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 showcase__lead">
            Pro Dashboards created to showcase all your important information in a glance
          </h2>
          <p className="feature__text">
              Contrast Pro admin dashboard was created with components that lets the user 
              see very vital and important information in a glance. 
              It was designed with different types of components used to showcase different types of data to the user.
          </p>
          <ul className="feature-2__list">
            <li>
              Get access to Over 10000 components included with the admin dashboard, some of which are buttons,
             forms, alerts, datepickers, cards and many more.
            </li>
            <li>
              More than 30 example pages that can easily be used to get you started on your react project.
            </li>
          </ul>
          <AnchorLink href="#pricing-plans" className="btn1 btn__inverse">
            Get Admin Pro
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};
