import React from 'react';


export const Showcase = () => {
  return (
    <div className="showcase">
      <div className="showcase__lead">Awesome Features</div>

      <div className="showcase__items">
        <div className="showcase__wrap showcase__list">
          {/* <!-- Item 1 start --> */}
          
          {/* <!-- Item 1 end --> */}
          {/* <!-- Item 2 start --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Contrast Pro Included</h2>
              <p className="showcase__text">
                Get access to the Contrast Design Bootstrap Pro UI kit and all its Pro features when you purchase this template.
              </p>
            </div>
          </div>
          {/* <!-- Item 2 end --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Easy Installation and Use.</h2>
              <p className="showcase__text">
                Get started using this template without much hassle. You can get started by installing the dependencies and running the project on your local machine
              </p>
            </div>
          </div>
          {/* <!-- Item 3 start --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Frequent Updates</h2>
              <p className="showcase__text">
                Contrast will be updated frequently to keep everything up to date and implement fixes
              </p>
            </div>
          </div>
          {/* <!-- Item 3 end --> */}
          {/* <!-- Item 4 start --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Technical Support</h2>
              <p className="showcase__text">
                We are here to help with any issues you might have. You can always contact us if there is any
              </p>
            </div>
          </div>
          {/* <!-- Item 4 end --> */}
          {/* <!-- Item 5 start --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Modularity and No Jquery</h2>
              <p className="showcase__text">
                Contrast does not use jquery and all the components are isolated from each other to encourage modularity and reusability
              </p>
            </div>
          </div>
          {/* <!-- Item 5 end --> */}
          {/* <!-- Item 6 start --> */}
          <div className="showcase__item">
            <div className="showcase__icon">
              <svg viewBox="0 0 40 40">
                <path
                  d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                  fill="#27AE60"
                />
                <path
                  d="M30.137 15.762L19.303 26.595a1.662 1.662 0 01-2.356 0l-5.417-5.417a1.664 1.664 0 010-2.356 1.664 1.664 0 012.357 0l4.238 4.238 9.655-9.655a1.664 1.664 0 012.357 0 1.665 1.665 0 010 2.357z"
                  fill="#fafafa"
                />
              </svg>
            </div>
            <div className="showcase__content">
              <h2 className="showcase__title">Fully Responsive Template</h2>
              <p className="showcase__text">
                We designed the admin dashboard template to be responsive on any screen.
                Every component was built to adjust based on the screen you are using.
              </p>
            </div>
          </div>
          {/* <!-- Item 6 end --> */}
        </div>
        <div className="showcase__wrap showcase__img">
          <img
            src={require('../../../components/images/adminphpro.png')}
            className="showcase__img--img"
            alt="#"
          />
        </div>
      </div>
    </div>
  );
};
