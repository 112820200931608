import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';
import { Hero } from '../../components/AdminAboutPro/React/Hero';
import { Feature } from '../../components/AdminAboutPro/React/Feature';
import { Showcase } from '../../components/AdminAboutPro/React/Showcase';
import { Comparison } from '../../components/AdminAboutPro/React/Comparison';
import { About as AboutSection } from '../../components/AdminAboutPro/React/About';
import '../../styles/scss/style.scss'

const AdminContrastPro = () => {

  return (
    <Layout>
      <Helmet>
        <title>React Bootstrap Admin Template - Contrast PRO + Admin Dashboard</title>
        <meta name="title" content="React Bootstrap Admin Template - Contrast PRO + Admin Dashboard" />
        <meta
          name="description"
          content="React Bootstrap admin templates beautifully designed for creating responsive admin dashboards, product, about, saas pages etc"
        />
        <meta property="og:title" content="React Bootstrap Admin Template - Contrast PRO + Admin Dashboard" />
        <meta
          property="og:description"
          content="React Bootstrap admin templates beautifully designed for creating responsive admin dashboards, product, about, saas pages etc"
        />
        <meta property="twitter:title" content="React Bootstrap Admin Template - Contrast PRO + Admin Dashboard" />
        <meta
          property="twitter:description"
          content="React Bootstrap admin templates beautifully designed for creating responsive admin dashboards, product, about, saas pages etc"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.devwares.com/product/admin-contrast-pro/" />
        <meta property="og:url" content="https://www.devwares.com/product/admin-contrast-pro/" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />

      </Helmet>
      <div className="container">
        <Hero />
        <Feature />
        <Showcase />
        <Comparison />
        <AboutSection />
      </div>
    </Layout>
  );
};

export default AdminContrastPro;
